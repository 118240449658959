import { navigate } from "gatsby"
import React, { useCallback, useState, useContext, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useForm } from "react-hook-form"
import AuthenticationContext from "../context/authentication/AuthenticationContext"
import StateContext from "../context/state/StateContext"
import { getUser, makeLogOut } from "../helpers/DrupalHelper"
import LayoutMinimal from "../components/Layout/Minimal"
import Button from "../components/Elements/Button"
import FloatingInput from "../components/Elements/Form/FloatingInput"
import NotificationContext from "../context/Notification/NotificationContext"
import { useLocation } from "@reach/router"

const Maintenance = () => {


    return (
        <LayoutMinimal
            title={"Scheduled Maintenance."}
            footer={"©2023 O2O. All rights reserved."}
            metaTitle={"Maintenance"}
        >
            <p className="text-reg-16 text-center mb-[20px]">
                We're currently performing scheduled maintenance on the O2O app. Stay tuned, we'll be back with you very soon!
            </p>

            <div className="flex justify-center items-center mt-6">

            </div>
        </LayoutMinimal>
    )
}

export default Maintenance
